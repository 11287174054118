import PropTypes from 'prop-types';

function PhoneIcon({ width, height }) {
	return (
		<svg
			width={width || '20'}
			height={height || '20'}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<desc>Created with Pixso.</desc>
			<defs />
			<path
				id="Vector"
				d="M14.36 12C14.4399 11.3398 14.5 10.6802 14.5 10C14.5 9.31982 14.4399 8.66016 14.36 8L17.74 8C17.9 8.64014 18 9.31006 18 10C18 10.6899 17.9 11.3599 17.74 12L14.36 12ZM12.59 17.5601C13.1899 16.4502 13.65 15.25 13.97 14L16.92 14C15.9512 15.6685 14.4141 16.9321 12.59 17.5601ZM12.34 12L7.66003 12C7.56006 11.3398 7.5 10.6802 7.5 10C7.5 9.31982 7.56006 8.6499 7.66003 8L12.34 8C12.4301 8.6499 12.5 9.31982 12.5 10C12.5 10.6802 12.4301 11.3398 12.34 12ZM10 17.96C9.17004 16.7598 8.5 15.4302 8.08997 14L11.91 14C11.5 15.4302 10.83 16.7598 10 17.96ZM6 6L3.07996 6C4.03882 4.32715 5.57483 3.06152 7.40002 2.43994C6.80005 3.5498 6.34998 4.75 6 6ZM3.07996 14L6 14C6.34998 15.25 6.80005 16.4502 7.40002 17.5601C5.57861 16.9316 4.0448 15.6675 3.07996 14ZM2.26001 12C2.09998 11.3599 2 10.6899 2 10C2 9.31006 2.09998 8.64014 2.26001 8L5.64001 8C5.56006 8.66016 5.5 9.31982 5.5 10C5.5 10.6802 5.56006 11.3398 5.64001 12L2.26001 12ZM10 2.02979C10.83 3.22998 11.5 4.56982 11.91 6L8.08997 6C8.5 4.56982 9.17004 3.22998 10 2.02979ZM16.92 6L13.97 6C13.657 4.76123 13.1936 3.56592 12.59 2.43994C14.4301 3.06982 15.96 4.33984 16.92 6ZM10 0C4.46997 0 0 4.5 0 10C0 12.6523 1.05359 15.1958 2.92896 17.0713C3.85754 17.9995 4.95996 18.7363 6.17322 19.2388C7.38647 19.7412 8.68677 20 10 20C12.6522 20 15.1957 18.9463 17.071 17.0713C18.9464 15.1958 20 12.6523 20 10C20 8.68701 19.7413 7.38623 19.2388 6.17334C18.7362 4.95996 17.9996 3.85742 17.071 2.92871C16.1425 2.00049 15.04 1.26367 13.8268 0.76123C12.6135 0.258789 11.3132 0 10 0Z"
				fill="#5568D0"
				fillOpacity="1.000000"
				fillRule="nonzero"
			/>
		</svg>
	);
}

PhoneIcon.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PhoneIcon;
