import PropTypes from 'prop-types';

function PhoneIcon({ width, height }) {
	return (
		<svg
			width={width || '24'}
			height={height || '24'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<clipPath id="clip1673_9859">
					<rect
						id="mingcute:phone-line"
						width="24.000000"
						height="24.000000"
						fill="white"
						fillOpacity="0"
					/>
				</clipPath>
			</defs>
			<rect
				id="mingcute:phone-line"
				width="24.000000"
				height="24.000000"
				fill="#FFFFFF"
				fillOpacity="0"
			/>
			<g clipPath="url(#clip1673_9859)">
				<path
					id="Vector"
					d="M8.172 15.8291C12.017 19.6738 15.58 20.0952 16.626 20.1338C17.89 20.1802 19.1801 19.1479 19.738 18.0908C18.848 17.0469 17.689 16.2368 16.42 15.3589C15.671 16.1069 14.748 17.4971 13.519 16.999C12.8199 16.7178 11.094 15.9229 9.58606 14.4141C8.07703 12.9058 7.28296 11.1802 7 10.4819C6.50195 9.25098 7.896 8.32617 8.64502 7.57715C7.76697 6.28711 6.97095 5.09814 5.92896 4.25293C4.85706 4.81299 3.81897 6.09277 3.86597 7.37402C3.90503 8.41992 4.32605 11.9829 8.172 15.8291ZM16.552 22.1328C15.1121 22.0801 11.031 21.5161 6.75696 17.2432C2.48401 12.9688 1.92102 8.88916 1.86694 7.44775C1.78699 5.25195 3.46899 3.11914 5.41199 2.28613C5.646 2.18506 5.90222 2.14648 6.15552 2.17432C6.40881 2.20215 6.65063 2.29541 6.85706 2.44482C8.46497 3.61816 9.57397 5.39502 10.527 6.78711C10.7252 7.07666 10.816 7.42627 10.7837 7.77539C10.7515 8.125 10.598 8.45166 10.35 8.7002L8.99402 10.0571C9.30896 10.752 9.94995 11.9502 11 13C12.05 14.0498 13.248 14.6909 13.944 15.0059L15.299 13.6499C15.5481 13.4014 15.8766 13.248 16.2271 13.2168C16.5775 13.1855 16.9279 13.2783 17.217 13.479C18.637 14.4629 20.3051 15.5562 21.521 17.1128C21.6826 17.3208 21.7854 17.5684 21.8187 17.8296C21.8519 18.0908 21.8145 18.3564 21.71 18.5981C20.873 20.5508 18.755 22.2139 16.552 22.1328Z"
					fill="#5568D0"
					fillOpacity="1.000000"
					fillRule="evenodd"
				/>
			</g>
		</svg>
	);
}

PhoneIcon.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PhoneIcon;
